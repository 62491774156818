import React from "react"
import { Form, Field } from "react-final-form"
import { validateEmail } from "../utils"
import { requestDemo } from "../actions"
// import { PartnerEventAnalytics } from "../utils/analytics"
import PropTypes from "prop-types"
import { setUTMSource } from "../utils/storage"

class RequestDemoForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSuccessModal: false,
      disableSendBtn: false,
      errorSubmition: false,
    }
  }

  componentDidMount() {
    setUTMSource()
  }

  validateFunc = values => {
    const errors = {}

    if (!values.fullName) {
      errors.fullName = "Please fill the above details"
    } else if (!values.fullName.trim()) {
      errors.fullName = "Please fill the above details"
    }

    if (!values.companyName) {
      errors.companyName = "Please fill the above details"
    } else if (!values.companyName.trim()) {
      errors.companyName = "Please fill the above details"
    }

    if (!values.jobTitle) {
      errors.jobTitle = "Please fill the above details"
    } else if (!values.jobTitle.trim()) {
      errors.jobTitle = "Please fill the above details"
    }

    if (!values.workEmail) {
      errors.workEmail = "Please fill the above details"
    } else if (!validateEmail(values.workEmail)) {
      errors.workEmail = "Please enter in correct format"
    }

    if (values.phoneNumber && Math.sign(values.phoneNumber) === -1) {
      errors.phoneNumber = "Please enter contact number"
    } else if (!values.phoneNumber) {
      errors.phoneNumber = "Please enter numbers only"
    }

    return errors
  }

  onSubmitFunc = async (values, form, callback) => {
    if (!values.contactNumber) {
      values = {
        ...values,
        contactNumber: 0,
      }
    }
    let UTMSource = JSON.parse(localStorage.getItem("UTMSource"))
    values = {
      ...values,
      source: UTMSource.source,
      medium: UTMSource.medium,
      content: UTMSource.content,
      campaign: UTMSource.campaign,
      page_ref: this.props.requestPage ? this.props.requestPage : "direct",
    }
    // PartnerEventAnalytics("Click_Submit_Button")
    try {
      dataLayer.push({
        event: "formSubmitted",
        formName: "Request for more info",
        event_category: "Form submit",
        event_label: "Contact Us",
      })
      await requestDemo(values)
      this.setState({ disableSendBtn: false })
      this.setState({ showSuccessModal: true }, () => {
        fbq("track", "Lead")
        setTimeout(() => {
          this.setState({ showSuccessModal: false })
        }, 3000)
      })
      setTimeout(() => {
        form.reset()
        callback()
      }, 3000)
    } catch (err) {
      this.setState({ disableSendBtn: false, errorSubmition: true })
    }
  }

  render() {
    const { showSuccessModal, disableSendBtn, errorSubmition } = this.state
    return (
      <>
        {!showSuccessModal ? (
          <Form
            onSubmit={this.onSubmitFunc}
            validate={this.validateFunc}
            render={({ handleSubmit, pristine, invalid, submitting, form, reset }) => (
              <form onSubmit={handleSubmit} id="request-for-more-demo">
                <div className="h5 font-weight-bold mb-4">Tell us about yourself</div>

                <Field name="fullName">
                  {({ input, meta }) => (
                    <div className={`form-group ${meta.touched && meta.error ? "invalid" : ""}`}>
                      <label>FULL NAME</label>
                      <input
                        type="text"
                        {...input}
                        placeholder=""
                        className="form-control"
                        // onBlur={e => {
                        //   if (e.target.value) PartnerEventAnalytics("Full_Name")
                        // }}
                      />
                      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                    </div>
                  )}
                </Field>

                <Field name="companyName">
                  {({ input, meta }) => (
                    <div className={`form-group ${meta.touched && meta.error ? "invalid" : ""}`}>
                      <label>COMPANY NAME</label>
                      <input
                        type="text"
                        {...input}
                        placeholder=""
                        className="form-control"
                        // onBlur={e => {
                        //   if (e.target.value) PartnerEventAnalytics("Company_Name")
                        // }}
                      />
                      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                    </div>
                  )}
                </Field>

                <Field name="companyWebsite">
                  {({ input, meta }) => (
                    <div className={`form-group ${meta.touched && meta.error ? "invalid" : ""}`}>
                      <label>
                        COMPANY WEBSITE <small>(OPTIONAL)</small>
                      </label>
                      <input
                        type="text"
                        {...input}
                        placeholder=""
                        className="form-control"
                        // onBlur={e => {
                        //   if (e.target.value) PartnerEventAnalytics("company_Website")
                        // }}
                      />
                      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                    </div>
                  )}
                </Field>

                <Field name="jobTitle">
                  {({ input, meta }) => (
                    <div className={`form-group ${meta.touched && meta.error ? "invalid" : ""}`}>
                      <label>JOB TITLE</label>
                      <input
                        type="text"
                        {...input}
                        placeholder=""
                        className="form-control"
                        // onBlur={e => {
                        //   if (e.target.value) PartnerEventAnalytics("Job_Title")
                        // }}
                      />
                      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                    </div>
                  )}
                </Field>

                <Field name="workEmail">
                  {({ input, meta }) => (
                    <div className={`form-group ${meta.touched && meta.error ? "invalid" : ""}`}>
                      <label>WORK EMAIL</label>
                      <input
                        type="email"
                        {...input}
                        placeholder=""
                        className="form-control"
                        // onBlur={e => {
                        //   if (e.target.value) PartnerEventAnalytics("Email")
                        // }}
                      />
                      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                    </div>
                  )}
                </Field>

                <Field name="phoneNumber">
                  {({ input, meta }) => (
                    <div className={`form-group ${meta.touched && meta.error ? "invalid" : ""}`}>
                      <label>PHONE</label>
                      <input
                        {...input}
                        placeholder=""
                        className="form-control"
                        type="number"
                        min={0}
                        // onBlur={e => {
                        //   if (e.target.value) PartnerEventAnalytics("Contact_Number")
                        // }}
                      />
                      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                    </div>
                  )}
                </Field>

                <div className="text-center">
                  <button type="submit" className={`btn btn-success`} disabled={submitting}>
                    SUBMIT
                  </button>
                </div>
              </form>
            )}
          />
        ) : (
          <div className="text-center my-5" id="request-for-more-demo-message">
            <img src="/images/success-submit.svg" alt="success" className="img-fluid" />

            <div className="h4 mt-5">Thank you for showing your interest, we will contact you soon.</div>
          </div>
        )}
      </>
    )
  }
}

RequestDemoForm.propTypes = {
  requestPage: PropTypes.string,
}

export default RequestDemoForm
