import React from "react"
import { Row, Col } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import FormContact from "../../components/contactForm"
import RequestDemoForm from "../../components/request-demo"
import PropTypes from "prop-types"

// const Services = ({ location }) => (
class Services extends React.Component {
  constructor(props) {
    super(props)
    this.state = { requestPage: "direct" }
  }

  componentWillMount() {
    if (this.props.location && this.props.location.state) {
      this.setState({ requestPage: this.props.location.state.page })
    }
  }

  render() {
    return (
      <Layout>
        <SEO
          title={"Contact Us"}
          titleDesc={"Contact us to learn more about our AI-enabled online education platform. "}
          titleKeywords={
            "fuse classroom, contact us, online learning platform, top online learning platforms, ai enabled education platform, online education platform, online learning platform, e learning platforms, lms platforms for education"
          }
          fbDes={"Contact us to learn more about our AI-enabled online education platform."}
          twitDesc={"Contact us to learn more about our AI-enabled online education platform."}
          seoLink={"request-for-more-info/"}
        />

        {/* top section */}
        <section role="top--section" className="pb-5 pt-2">
          {/* background overlay */}
          <div className="bg-overlay" />

          {/* content */}
          <article className="container">
            <Row className="align-items-center mt-5">
              <Col lg={6} className="text-white">
                <h1 className="font-weight-bold text-white">Contact Us</h1>
                <div className="h4 mt-4 text-white font-weight-normal">
                  See how Fuse Classroom helps your school administrators, faculty, and students to completely run
                  online.
                </div>

                <div className="mt-4">
                  <img src="/images/contact.webp" alt="contact" className="img-fluid" width="640" height="auto" />
                </div>
              </Col>

              <Col lg={{ span: 5, offset: 1 }}>
                <div className="bg-light rounded p-4 my-4">
                  <RequestDemoForm requestPage={this.state.requestPage} />
                  {/* <FormContact /> */}
                </div>
              </Col>
            </Row>
          </article>
        </section>
      </Layout>
    )
  }
}

Services.propTypes = {
  location: PropTypes.object,
}

export default Services
